<script setup lang="ts">
import { ActivityApi } from '~/net/apis/activity_api'

const id = useRouteParam<string>('id') || ''
const onClickLeft = () => history.back()
const data = ref()
async function handleActiveDetail() {
  try {
    console.log(2344, id)
    const res = await ActivityApi.activeDetail({ id: id.value })
    console.log(res)
    if (res.code === 200) {
      data.value = res.data
    }
  } catch (e) {}
}
await handleActiveDetail()
</script>

<template>
  <div h-screen>
    <van-nav-bar :title="data?.title || data?.title" left-arrow @click-left="onClickLeft" />
    <div p-4>
      <ImgWrapper rounded-1 h-48 w-full :src="(data as Activity)?.masterPicture" placeholder="/merchant_error.png" />
      <div ct-D0D0D0 class="activity" v-html="data?.activityDoc || ''" />
    </div>
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
  font-size: 18px;
  max-width: 75%;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
.activity{
  position: relative;
  background: linear-gradient(180deg, rgba(36, 41, 67, 0) -0.72%, #242943 8.32%, #111425 100%);
  padding-top: 40px;
  margin-top: -40px;
}
</style>
